import './src/css/variables.css';
import './src/css/commonStyles.css';

import React from 'react';

import AppRoot from './src/containers/Root';

//same is also implemented in 'gatsby-ssr.js'

export function wrapRootElement({ element }) {
	return <AppRoot>{element}</AppRoot>;
}
