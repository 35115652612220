import React from 'react';

//This SVG component will be imported in the root and wud mostly / preferably render right after opening body tag
//other SVGs down the line can reference the reusable symbols and defs from this svg

export default function SvgCommonReusableElements(props) {
	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 0 0"
			width="0"
			height="0"
			style={{ visibility: 'hidden', position: 'absolute', pointerEvents: 'none' }}
			{...props}
		></svg>
	);
}
