import React from 'react';

import SvgCommonReusableElements from '@components/svgJsx/svgCommonReusableElements.js';
// import ToastNotifier from '@components/ToastNotifier';
// import NetworkCheck from '@providers/NetworkAvailabilityCheck';

//this component will be used in gatsby-browser.js and gatsby-ssr.js to wrap the gatsby root element
//in this we can provide all Context Providers

export default function AppRoot({ children }) {
	return (
		<>
			<SvgCommonReusableElements />
			{/* <ToastNotifier>
				<NetworkCheck> */}
			{children}
			{/* </NetworkCheck>
			</ToastNotifier> */}
		</>
	);
}
